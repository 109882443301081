import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import MerchantSignUpButton from '../components/merchantSignUpButton'

export default function About () {
  return (
    <Layout>
      <section>
        <div className='row align-center justify-center' style={{ minHeight: 400 }}>
          <div className='col w75 text-center'>
            <div className="inner">
              <h1>We build the tools for affordable local delivery</h1>
              <p className='lede'>Same-day on-demand local delivery technology platform.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='well callout'>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h2>The business</h2>
              <p>Trexity is a same-day on-demand local delivery technology platform for online and bricks &amp; mortar businesses. We enable businesses of all sizes to deliver physical products in near real-time to their customers.</p>
              <p className='mb'>Trexity supports single and multi-stop deliveries with requirements that allow our customers to scale their businesses faster—while not gouging with extra fee, subscriptions, or heavy commissions.</p>
              <MerchantSignUpButton />
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/about-business.jpg" alt="Office with chairs" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className='m-mb-0'>
        <div className='row equal'>
          <div className='col mr m-mr-0'>
            <div className="well inner half text-center">
              <p className='mb-0' style={{ fontSize: 50 }}><strong>2018</strong></p>
              <span className='lede'>Founded</span>
            </div>
          </div>
          <div className='col ml mr m-ml-0 m-mr-0'>
            <div className="well inner half text-center">
              <p className='mb-0' style={{ fontSize: 50 }}><strong>13</strong></p>
              <span className='lede'>Employees</span>
            </div>
          </div>
          <div className='col ml mr mr m-ml-0 m-mr-0'>
            <div className="well inner half text-center">
              <p className='mb-0' style={{ fontSize: 50 }}><strong>$5M</strong></p>
              <span className='lede'>Seed funding</span>
            </div>
          </div>
          <div className='col ml m-ml-0'>
            <div className="well inner half text-center">
              <p className='mb-0' style={{ fontSize: 50 }}><strong>50K+</strong></p>
              <span className='lede'>Deliveries</span>
            </div>
          </div>
        </div>
      </section> */}

      <section className='well callout'>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/about-people.jpg" alt="Photos of the team" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>The people</h2>
              <p>We are entrepreneurs, individuals, and learners. Technology lives at our core and customer service has been engrained in us from the beginning. We all have different talents, but we share the same values. </p>
              <div className='row preserve'>
                <div className="col">
                  <ol>
                    <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                      <i className='material-icons brand' style={{ marginRight: 10 }}>check_circle</i>
                      Honest
                    </li>
                    <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                      <i className='material-icons brand' style={{ marginRight: 10 }}>check_circle</i>
                      Respectful
                    </li>
                    <li className='row preserve align-center'>
                      <i className='material-icons brand' style={{ marginRight: 10 }}>check_circle</i>
                      Humble
                    </li>
                  </ol>
                </div>
                <div className="col">
                  <ol>
                    <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                      <i className='material-icons brand' style={{ marginRight: 10 }}>check_circle</i>
                      Curious
                    </li>
                    <li className='row preserve align-center' style={{ marginBottom: 15 }}>
                      <i className='material-icons brand' style={{ marginRight: 10 }}>check_circle</i>
                      Open
                    </li>
                    <li className='row preserve align-center'>
                      <i className='material-icons brand' style={{ marginRight: 10 }}>check_circle</i>
                      Responsible
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row align-center justify-center'>
          <div className='col text-center'>
            <div className="inner">
              <h2>The mission</h2>
              <p className='mb'>Help local merchants get their goods to local customers and create a sustainable community.</p>
              <Link className='button-link button-link-brand' to='/careers/'>Join us</Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
